<!--
 * @Author: your name
 * @Date: 2021-03-04 15:11:49
 * @LastEditTime: 2021-03-12 17:12:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\divs\doctor\detail.vue
-->
<template>
  <div class="detail">
    <div class="doctor-head" />
    <div class="doctor-body">
      <div class="introduce">
        <div class="info">
          <div class="pic">
            <img class="img" :src="doctorList.txljdz">
          </div>
          <div class="con">
            <div class="name">
              {{ doctorList.ysxm }}
              <span class="post">{{ doctorList.zc==null?'无':doctorList.zc }}</span>
            </div>
            <div class="department">
              {{ doctorList.ksmc }}
            </div>
            <div class="statistic">
              <div class="statistic-item">
                好评率：
                <div class="num">
                  {{ doctorList.hpl==null?'无':doctorList.hpl }}
                </div>
              </div>
              <div class="statistic-item">
                问诊量
                <div class="num">
                  {{ doctorList.wzl==null?'无':doctorList.wzl }}
                </div>
              </div>
            </div>
          </div>
          <div v-show="doctorList.follow==false" class="btn" @click="Attention(doctorList.id)">
            关注
          </div>
          <div
            v-show="doctorList.follow==true"
            class="btn active"
            @click="unAttention(doctorList.id)"
          >
            已关注
          </div>
        </div>
        <div :class="['desc',{'packup':hide}]">
          <span class="label">
            擅长：
          </span>
          {{ doctorList.yssc==null?'无':doctorList.yssc }}
          <span v-if="doctorList.yssc &&doctorList.yssc .length>40" class="showIco" @click="showText">
            <van-icon v-show="hide" name="arrow-down" />
            <van-icon v-show="!hide" name="arrow-up" />
          </span>
        </div>
        <div :class="['desc',{'packup':hide1}]">
          <span class="label">
            简介：
          </span>
          {{ doctorList.ysjj==null?'无':doctorList.ysjj }}
          <span v-if="doctorList.ysjj && doctorList.ysjj.length>40" class="showIco" @click="showjianjie">
            <van-icon v-show="hide1" name="arrow-down" />
            <van-icon v-show="!hide1" name="arrow-up" />
          </span>
        </div>
      </div>
      <div class="service">
        <div class="title">
          服务
        </div>
        <div class="con">
          <div
            v-for="(item,index) in serviceList"
            :key="index"
            class="service-item"
            @click="togoPage(item)"
          >
            <img class="img" :src="item.url">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="comment">
        <div class="title">
          患者评价
          <span class="num">(10022)</span>
          <div class="rate">
            好评率
            <span class="num">10.3%</span>
          </div>
        </div>
      </div>
      <!-- <div class="evaluation">
        <load-more :data-source="evaluateList" :total="total" :loading="loading" @load="onLoad">
          <AppraiseItem :evaluate-list="evaluateList" />
        </load-more>
      </div>-->
      <AppraiseItem :evaluate-list="evaluateList" />
    </div>
    <div class="more-btn">
      <div v-if="sunEvaluateList.length>2" class="btn-in" @click="getMore">
        更多{{ total-2 }}个评价
      </div>
    </div>
    <van-popup v-model="show" class="content">
      <div class="expert" :data-id="102" @click="expert">
        {{ doctorList.ksmc }}(专家门诊)
      </div>
      <div class="border" />
      <div class="particular" :data-id="103" @click="expert">
        {{ doctorList.ksmc }}(特需门诊)
      </div>
    </van-popup>
  </div>
</template>
<script>
import {appointModel} from '../../api/appoint'
import {CONSTANT,DOCTOR_DETAIL}from '../../utils/constant'
import {setItem} from '../../utils/cache'
import AppraiseItem from '../../components/category/appraiseItem'
export default {
  components:{
    AppraiseItem

  },

  data(){
    return{
      doctorList:{},
      evaluateList:[],
      sunEvaluateList:[],
      hide:true,
      hide1: true,
      show: false,
      total:0,
      page:1,
      pageSize:10,
      serviceList:[
        {
          title:'在线续方',
          url: require('../../assets/img/online.png'),
          pagePath:""
        },
        {
          title:'在线预约',
          url: require('../../assets/img/subscribe.png'),
          pagePath:"/scheduling"
        },
        {
          title:'今日挂号',
          url: require('../../assets/img/health-record.png'),
          pagePath:"/appointment"
        }
      ]
    }
  },
  mounted(){
    this.getDoctorList()
    this.getList()
  },
  methods:{
    showText(){
      this.hide = !this.hide
    },
    showjianjie(){
      this.hide1 = !this.hide1
    },
    async getDoctorList(){
      const {yljgdm,ksdm,ysgh}=this.$route.query
      const result=await appointModel.depDoc({yljgdm,ksdm,ysgh})
      this.doctorList=result.data[0]|| {}
    },
    showPopup(){
      this.show=true
    },
    expert(e){
      this.saveDoctorDeatil()
      const {yljgdm,ysgh}=this.$route.query
      const Id =e.target.dataset.id
      this.$router.push({
        path:'/scheduling',
        query: {
          yljgdm:yljgdm,
          ysgh:ysgh,
          id:Id
        }
    })
    this.show=false
    },
    saveDoctorDeatil(){
      setItem(DOCTOR_DETAIL,this.doctorList)
    },
    togoPage(item){
       //const {yljgdm,ksdm,ysgh}=this.$route.query
       if(item.title==='在线预约'){
         this.saveDoctorDeatil()
         const {yljgdm,ysgh,hylx}=this.$route.query
         this.$router.push({
           path:'/scheduling',
           query: {
             yljgdm:yljgdm,
             ysgh:ysgh,
             id:hylx
           }
         })
       }

    },
    async Attention(id){
        const result=await appointModel.concern({ doctorid:id})
        if(result.code===CONSTANT.SUCCESS_CODE){
          this.getDoctorList()
        }else{
           this.$toast(result.msg)
        }
    },
    async unAttention(id){
      const result =await appointModel.unconcern({doctorid:id})
      if(result.code===CONSTANT.SUCCESS_CODE){
        this.getDoctorList()
      }else{
        this.$toast(result.msg)
      }
    },
    getList(){
      const {yljgdm,ksdm,ysgh}=this.$route.query
      let params={
        page:this.page,
        pageSize:this.pageSize,
        yljgdm:yljgdm,
        ksdm:ksdm,
        ysgh:ysgh

      }
      appointModel.getWrite(params).then(res=>{
          this.sunEvaluateList=res.data||[]
        const data=res.data||[]
        // console.log( this.evaluateList,' this.evaluateList')
          this.evaluateList=data.slice(0,2)||[]
          this.total=res.count

      })
    },
    getMore(){

      const {yljgdm,ksdm,ysgh}=this.$route.query
      this.$router.push({
        path: '/doctor/evaluate',
        query: {
          yljgdm:yljgdm,
          ksdm:ksdm,
          ysgh:ysgh
        }
    })
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
div {
  box-sizing: border-box;
}
.detail {
  .doctor-head {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 284px;
    background: @theme-color;
  }
  .doctor-body {
    position: relative;
    z-index: 2;
    padding: 40px 32px 60px;
    .introduce {
      margin-bottom: 40px;
      padding: 32px 32px;
      line-height: 33px;
      font-size: @font-size-26;
      color: @middle-font-color;
      background: @white-color;
      border-radius: 20px;

      .info {
        position: relative;
        display: flex;
        margin-bottom: 24px;
      }

      .pic,
      .img {
        width: 148px;
        height: 148px;
        border-radius: 50%;
        background: #ccc;
      }

      .con {
        flex: 1;
        padding-left: 32px;
        line-height: 33px;
        font-size: 26px;
        color: @middle-font-color;

        .name {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          line-height: 45px;
          font-size: 32px;
          font-weight: bold;
          color: @dark-font-color;

          .post {
            margin-left: 24px;
            font-weight: normal;
            font-size: @font-size-24;
          }
        }
      }

      .statistic {
        display: flex;
        text-align: center;
        margin-top: 20px;

        .statistic-item {
          margin-right: 72px;

          .num {
            padding-top: 12px;
            color: @theme-color;
          }
        }
      }

      .btn {
        position: absolute;
        top: 0px;
        right: 0;
        z-index: 2;
        width: 130px;
        height: 48px;
        line-height: 48px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: @theme-color;
        border: 1px solid @theme-color;
        border-radius: 30px;

        &.active {
          color: @white-color;
          background: @theme-color;
        }
      }

      .desc {
        margin-bottom: 24px;
        position: relative;
        padding-right: 20px;
        line-height: 1.5;

        &:last-child {
          margin-bottom: 0;
        }

        .label {
          margin-bottom: 24px;
          font-weight: bold;
          color: @theme-color;
        }
        .showIco{
          position: absolute;
          bottom: 0;
          right: 0;
          font-size:28px;
          font-weight: 700;
          color:#3A8AE5;
        }
      }
      .packup{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-line-clamp:2;
      overflow: hidden;
      text-overflow: ellipsis;
      }
    }
    .service {
      margin-bottom: 40px;

      .title {
        margin-bottom: 40px;
        font-size: @font-size-32;
        font-weight: bold;
      }

      .con {
        display: flex;
        justify-content: space-between;

        .service-item {
          width: 33.3%;
          line-height: 40px;
          font-size: 28px;
          color: @dark-font-color;
          text-align: center;

          .img {
            display: block;
            margin: 0 auto;
            width: 116px;
            height: 116px;
          }
        }
      }
    }
    .comment {
      .title {
        display: flex;
        margin-bottom: 32px;
        align-items: center;
        font-size: @font-size-32;
        font-weight: bold;

        & > .num {
          align-self: flex-end;
          font-size: 22px;
          font-weight: normal;
          color: @light-font-color;
        }

        .rate {
          flex: 1;
          align-self: right;
          text-align: right;
          font-size: 24px;
          font-weight: normal;

          .num {
            margin-left: 10px;
            font-weight: bold;
            color: @theme-color;
          }
        }
      }
    }
    .evaluation {
      height: 300px;
      overflow: auto;
    }
  }
  .more-btn {
    text-align: center;
    margin-bottom: 60px;
    .btn-in {
      display: inline-block;
      margin: 0 auto;
      min-width: 26px;
      height: 48px;
      padding: 0 49px;
      line-height: 48px;
      text-align: center;
      font-size: 26px;
      color: #1c9cf6;
      background: #d8efff;
      border-radius: 30px;
    }
  }
  .content {
    width: 686px;
    height: 209px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .expert {
      margin-bottom: 32px;
       width: 100%;
       text-align: center;
    }
    .border {
      border-bottom: 1px solid#ececec;
      width: 100%;
    }
    .particular {
      margin-top: 32px;
       width: 100%;
       text-align: center;
    }
  }
}
</style>
